.grading-view {
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
}

.grading-nav {
  background-color: #3d544c;
  width: 15%;
  height: 100%;
  padding-top: 30px;
}

.grading-banner {
  min-height: 80px;
  max-height: 100px;
  background-color: black;
  display: flex;
  justify-content: space-between;
}

.grading-banner .logo {
  height: 100px;
  padding: 10px;
}

.grading-banner .logo-text {
  height: 50px;
  padding: 10px;
  margin-top: 30px;
}

.grading-body {
  flex-direction: row;
  display: flex;
  flex: 1;
  height: 100vh;
}

.grading-content {
  padding: 10px;
  width: 100%;
}

html,
body,
#root {
  height: 100%;
}

.grading-steps {
  display: inline-block;
  vertical-align: top;
  padding-right: 50px;
  width: 20%;
  min-width: 200px;
  max-width: 300px;
}

.grading-step-body {
  display: inline-block;
  width: 80%;
  min-width: 600px;
}

.grading-step-notes {
  border-top: 0.75px solid rgba(0, 0, 0, 0.38);
  font-size: 0.75em;
  margin-top: 10px;
  padding-top: 10px;
}

.grading-step-notes h4 {
  font-size: 1.5em;
  vertical-align: top;
  text-align: center;
  font-weight: normal;
}

.grading-checklist {
  width: 100%;
}

.grading-checkbox {
  padding-left: 0px;
}

.grading-step-next {
  padding-top: 25px;
  text-align: right;
}

.grading-step-main {
  width: 75%;
  min-height: 415px;
  max-height: 415px;
  display: inline-block;
}

.grading-step-main video {
  max-height: inherit;
}

.grading-step-sidebar {
  max-width: 22%;
  display: inline-block;
  vertical-align: top;
}

.grading-step-card {
  min-width: 200px;
  max-width: 22%;
  margin: 15px;
}

.grading-step-header {
  vertical-align: top;
  text-align: center;
}

.grading-step-info {
  margin-top: 10px;
  border-top: 0.75px solid rgba(0, 0, 0, 0.38);
  padding-top: 8px;
}

.grading-step-item {
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  display: flex;
}

.grading-map {
  min-width: 300px;
  min-height: 500px;
  width: 100%;
  display: inline-block;
}

.grade-overview-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-right: 5%;
}

.grade-overview-sidecard {
  display: inline-block;
  width: 33%;
  margin-right: 5%;
  align-self: flex-start;
}

.grade-overview-sidecard .MuiCardHeader-root {
  padding-bottom: 0px;
}

.grade-overview-sidecard .profile-row-large {
  display: block;
  font-size: large;
}

.grade-overview-sidecard .profile-row-small {
  display: inline-block;
  width: 45%;
  font-size: small;
}

.grade-overview-table {
  min-width: 450px;
  margin-top: 20px;
}

.grade-overview-restrow {
  background-color: #dfe6e9;
}

.approveButton {
  background-color: #27ae60;
  margin: 10px;
}

.backButton {
  padding-right: 50px;
  display: inline-block;
}

.approvalButtons {
  text-align: right;
  display: flex;
}

.center-spinner {
  margin-left: 50%;
  margin-top: 25%;
}

.grading-nav ul {
  list-style: none;
}

.grading-nav ul li a {
  color: white;
}

.grading-review-card {
  min-width: 300px;
  width: 45%;
  display: inline-block;
  margin: 15px;
  min-height: 400px;
  vertical-align: top;
}

.grading-review-card-video {
  max-height: 250px;
}

.grading-review-items {
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  display: flex;
}

.grading-map-review {
  min-height: 200px;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.join-splash {
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #3d544c;
}

.join-logos {
  flex: 1;
  flex-direction: column;
  display: flex;
  margin-left: 40%;
  margin-top: 5%;
}

.join-splash .logo {
  height: 150px;
  width: 300px;
}

.join-splash .logo-text {
  height: 50px;
  width: 300px;
}

.join-splash .login {
  padding-left: 15px;
  width: 300px;
}

.join-splash .join-text {
  font-weight: bold;
  width: 315px;
  text-align: center;
}

.title {
  font-family: "Avenir";
  font-weight: 800;
  size: 24px;
}
